import React, { PureComponent } from "react";
import axios from "axios";
import firebase from "firebase/app";
import "firebase/auth";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import "../css/style.css";
import "../css/requestForm.css";
import Town_80_Light from "../assets/fonts/Town80Text-Light.otf";
import { createOAuthHeader521 } from "../actions/types";

import {
  addToken,
  onLIEmailChange,
  onLIPasswordChange,
  attemptLogin,
  // onLIClearFields,
  onLIError,
  onASNameChange,
} from "../actions";

import "../css/style.css";
import { Form } from "react-bootstrap";

class Login extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      forgotPasswordClicked: false,
      forgotPasswordMessage: "",
    };
  }

  async UNSAFE_componentWillMount() {
    const displayName = localStorage.getItem("displayName");
    const accountId = localStorage.getItem("accountId") || "";
    const contactId = localStorage.getItem("contactId");

    if (contactId !== "undefined") {
      this.props.onASNameChange(displayName, accountId, contactId);
    }
  }
  checkForEmail = async () => {
    try {
      let AUTHORIZATION_HEADER = createOAuthHeader521(
        "GET",
        "521",
        "1",
        this.props.email
      );
      let checkEmailResponse = await axios.get(
        "https://secret-retreat-76675.herokuapp.com/api/auth/email",
        {
          params: {
            email: this.props.email,
            auth_header: AUTHORIZATION_HEADER,
          },
        }
      );
      let data = checkEmailResponse.data;
      let numberOfRecords = data.searchResults.length;
      return {
        success: true,
        numberOfRecords,
        firstName: data.recordDetails.entityid,
        accountId: data.recordDetails.company.internalid,
        accountName: data.recordDetails.company.name,
        contactId: data.recordDetails.id,
        email: data.recordDetails.email,
      };
    } catch (err) {
      console.log("checkForEmail err", err);
      return { success: false, error: err };
    }
  };
  attemptLogin = async () => {
    this.setState({ forgotPasswordClicked: false });
    if (this.props.email === "" || this.props.password === "") {
      this.props.onLIError("Please enter a valid email and/or password.");
      return;
    }
    // 1) login to firebase
    try {
      let user = await firebase
        .auth()
        .signInWithEmailAndPassword(this.props.email, this.props.password);

      // 2) check if user has verified their email address
      if (!user.user.emailVerified) {
        this.props.onLIError("Email not verified");
        return;
      }
    } catch (error) {
      this.props.onLIError("The username and/or password are incorrect.");

      return;
    }
    // 3) get netsuite accountId
    try {
      let emailCheckResponse = await this.checkForEmail();
      localStorage.setItem("email", emailCheckResponse.email);
      localStorage.setItem("accountId", emailCheckResponse.accountId);
      localStorage.setItem("contactId", emailCheckResponse.contactId);
      this.props.onASNameChange(
        emailCheckResponse.firstName,
        emailCheckResponse.accountId,
        emailCheckResponse.contactId
      );
    } catch (err) {
      this.props.onLIError("Please contact support@livunltd.com");

      console.log("Error logging in");
      return;
    }
  };

  forgotPasswordPressed = async () => {
    if (this.props.email === "") {
      this.setState({
        forgotPasswordClicked: true,
        forgotPasswordMessage: "Please enter your email.",
      });
      return;
    }

    try {
      await firebase.auth().sendPasswordResetEmail(this.props.email);
      this.setState({
        forgotPasswordClicked: true,
        forgotPasswordMessage: "Forgot Password email sent.",
      });
    } catch (err) {
      this.setState({
        forgotPasswordClicked: true,
        forgotPasswordMessage: "Email not sent. Please verify your email.",
      });
    }
  };

  render() {
    return (
      <div style={{ marginTop: "50px" }}>
        {!this.props.isLoggedIn && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p
              className="welcome"
              style={{
                //  marginTop: "30px",
                fontSize: "36px",
                letterSpacing: 0.4,
              }}
            >
              SIGN IN
            </p>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                this.props.attemptLogin();
                this.attemptLogin();
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Form.Group className="form_item">
                  <Form.Control
                    type="email"
                    placeholder="EMAIL"
                    value={this.props.email}
                    onChange={(text) => {
                      this.setState({
                        forgotPasswordClicked: false,
                        forgotPasswordMessage: "",
                      });

                      this.props.onLIEmailChange(text.target.value);
                    }}
                    style={{
                      textAlign: "center",
                      minWidth: "300px",
                      padding: "10px",
                      height: "60px",
                      border: "2px #EFEFEF solid",
                    }}
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="form_item">
                  <Form.Control
                    type="password"
                    placeholder="PASSWORD"
                    value={this.props.password}
                    onChange={(text) => {
                      this.setState({
                        forgotPasswordClicked: false,
                        forgotPasswordMessage: "",
                      });
                      this.props.onLIPasswordChange(text.target.value);
                    }}
                    style={{
                      textAlign: "center",
                      minWidth: "300px",
                      padding: "10px",
                      height: "60px",
                      border: "2px #EFEFEF solid",
                    }}
                  ></Form.Control>
                </Form.Group>
                {this.props.loginError !== "" && (
                  <div
                    style={{
                      color: "#f34",
                      marginTop: 10,
                      paddingLeft: 10,
                      marginBottom: 10,
                      fontFamily: "Town_80_Light",
                    }}
                  >
                    {this.props.loginError}
                  </div>
                )}
                {this.state.forgotPasswordClicked && (
                  <p style={{ fontFamily: "Town_80_Light" }}>
                    {this.state.forgotPasswordMessage}
                  </p>
                )}
                {this.props.loading && (
                  <div>
                    <p style={{ fontFamily: "Town_80_Light" }}>signing in...</p>
                  </div>
                )}

                <button
                  className="login_button"
                  style={{
                    marginTop: "10px",
                    color: "white",
                    fontSize: "20px",
                    backgroundColor: "#2d506c",
                    padding: "10px",
                    width: "190px",
                    letterSpacing: "2.5px",
                    marginBottom: "5px",
                  }}
                  type="Submit"
                >
                  LOGIN
                </button>
              </div>
            </Form>
          </div>
        )}
        <br />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            onClick={() => {
              this.forgotPasswordPressed();
            }}
            style={{ border: "none", backgroundColor: "transparent" }}
          >
            <p
              style={{
                color: "#2d506c",
                fontFamily: Town_80_Light,
              }}
            >
              FORGOT PASSWORD?
            </p>
          </button>
          <div style={{ width: "2rem" }}></div>
          <Link
            to={"/sign-up"}
            style={{
              color: "#2d506c",
              fontFamily: Town_80_Light,
              textDecoration: "none",
              marginRight: "40px",
            }}
          >
            CREATE PROFILE
          </Link>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    email: state.auth.email,
    password: state.auth.password,
    loginError: state.auth.error,
    token: state.auth.token,
    loading: state.auth.loading,
    isLoggedIn: !!state.account.contactId,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    addToken,
    onASNameChange,
    onLIEmailChange,
    onLIPasswordChange,
    onLIError,
    attemptLogin,
  })(Login)
);
