import React, { PureComponent } from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import axios from "axios";
import ChangePasswordForm from "./ChangePasswordForm";
import "../css/style.css";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createOAuthHeader521 } from "../actions/types";

class Account extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      userInfo: {},
      email: localStorage.getItem("email"),
      showChangePassword: false,
      changePasswordSuccess: false,
      account: {},
    };
  }

  componentWillMount = async () => {
    let AUTHORIZATION_HEADER = createOAuthHeader521(
      "GET",
      "521",
      "1",
      this.state.email
    );
    let checkEmailResponse = await axios.get(
      "https://secret-retreat-76675.herokuapp.com/api/auth/email",
      {
        params: {
          email: this.state.email,
          auth_header: AUTHORIZATION_HEADER,
        },
      }
    );
    this.setState({
      account: checkEmailResponse?.data?.searchResults[0]?.columns,
    });
  };

  handleShow = () => {
    this.setState({
      showChangePassword: !this.state.showChangePassword,
      changePasswordSuccess: false,
    });
  };

  handleClose = (passwordChanged) => {
    this.setState({ showChangePassword: false });
  };

  changePasswordSuccess = () => {
    this.setState({ changePasswordSuccess: true });
    setTimeout(() => {
      this.setState({ changePasswordSuccess: false });
    }, 2000);
  };

  render() {
    const { entityid, company } = this.state.account;
    return (
      <Container
        style={{
          backgroundColor: "#2d506c",
          color: "white",
          padding: "10px 0px",
        }}
      >
        <Row style={{ margin: 0 }}>
          <Col
            style={{
              display: this.state.showChangePassword ? "" : "none",
              backgroundColor: "#2d506c",
            }}
          >
            <ChangePasswordForm
              handleClose={this.handleClose}
              email={this.state.email}
              successfulChange={this.changePasswordSuccess}
            />
          </Col>
          <Col
            className="info"
            style={{
              display: !this.state.showChangePassword ? "" : "none",
              backgroundColor: "#2d506c",
            }}
          >
            <div>
              NAME: <p>{entityid && entityid.toUpperCase()}</p>
            </div>
            <div>
              EMAIL: <p>{this.state.email && this.state.email.toUpperCase()}</p>
            </div>
            <div>
              COMPANY: <p>{company && company.name.toUpperCase()}</p>
            </div>

            {this.state.changePasswordSuccess && (
              <p
                style={{
                  backgroundColor: "green",
                  color: "white",
                  textAlign: "center",
                }}
              >
                PASSWORD CHANGED
              </p>
            )}
            <Button
              variant="none"
              style={{
                color: "white",
                border: "2px white solid",
                marginRight: "10px",
              }}
              onClick={() => this.handleShow()}
            >
              CHANGE PASSWORD
            </Button>
            <Button
              href="/login"
              variant="none"
              onClick={() => {
                localStorage.removeItem("contactId");
                localStorage.removeItem("accountId");
                localStorage.removeItem("email");
                this.props.onASClearFields();
              }}
              style={{
                color: "white",
                border: "2px white solid",
              }}
            >
              LOGOUT
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  email: state.account.email,
  displayName: state.account.displayName,
});

export default withRouter(connect(mapStateToProps, {})(Account));
