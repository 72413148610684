import {
  LOGIN_ATTEMPTED,
  LI_EMAIL_CHANGED,
  LI_PASSWORD_CHANGED,
  LI_TOKEN_CHANGED,
  LI_CLEARALL,
  LI_ERROR,
} from "../actions/types";

const INITIAL_STATE = {
  email: "",
  password: "",
  error: "",
  token: "",
  loading: false,
};

const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGIN_FAIL = "LOGIN_FAIL";

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGIN_ATTEMPTED:
      return { ...state, loading: true, error: "" };
    case LOGIN_SUCCESS:
      return INITIAL_STATE;
    case LOGIN_FAIL:
      return INITIAL_STATE;
    case LI_EMAIL_CHANGED:
      return { ...state, email: action.payload, loading: false, error: "" };
    case LI_PASSWORD_CHANGED:
      return { ...state, password: action.payload, loading: false, error: "" };
    case LI_TOKEN_CHANGED:
      return { ...state, token: action.payload, loading: false, error: "" };
    case LI_CLEARALL:
      return INITIAL_STATE;
    case LI_ERROR:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
}
