import React, { PureComponent } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { LOCATIONS } from "../actions/types";
import search_icon from "../assets/buttons/search-icon.png";
import axios from "axios";
import { createOAuthHeader521 } from "../actions/types";
import "../css/partDetail.css";

class PartsDetail extends PureComponent {
  state = {
    email: localStorage.getItem("email"),
    addressSearch: "",
    distanceCalc: 0,
    account: {},
    calculating: false,
    error: "",
  };

  componentWillMount = async () => {
    let AUTHORIZATION_HEADER = createOAuthHeader521(
      "GET",
      "521",
      "1",
      this.state.email
    );
    let checkEmailResponse = await axios.get(
      "https://secret-retreat-76675.herokuapp.com/api/auth/email",
      {
        params: {
          email: this.state.email,
          auth_header: AUTHORIZATION_HEADER,
        },
      }
    );
    this.setState({
      account: checkEmailResponse.data?.searchResults[0]?.columns,
    });
  };

  onKeyPress(event) {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  }

  distanceMatrix = async () => {
    this.setState({ calculating: true });

    if (this.state.addressSearch === "") {
      for (var i = 0; i < LOCATIONS.length; i++) {
        LOCATIONS[i]["distance"] = 0;
      }

      this.setState({ error: "Please Enter an Address." });
    } else {
      let locationsArray = [this.state.addressSearch];

      LOCATIONS.map((item, i) => {
        locationsArray.push(LOCATIONS[i].address);
        return 0;
      });

      let postBody = {
        locations: locationsArray,
        options: {
          allToAll: false,
          manyToOne: false,
          unit: "m",
        },
      };

      let postBodyString = JSON.stringify(postBody);

      try {
        let distanceMatrix = await axios.get(
          "https://secret-retreat-76675.herokuapp.com/api/data/distance",
          {
            params: {
              postBodyString,
            },
          }
        );

        for (let i = 0; i < LOCATIONS.length; i++) {
          LOCATIONS[i]["distance"] = distanceMatrix.data.distance[i + 1];
        }
      } catch (err) {
        this.setState({
          calculating: false,
          error: "Error Calculating Distance. Please try again.",
        });

        console.log("distanceMatrix err", err);
      }
    }
    this.setState({ distanceCalc: Math.random(), calculating: false });
  };

  hideModal = () => {
    this.setState({ addressSearch: "", error: "" });
    this.props.close();
  };
  renderDetails() {
    let locationsSortedArray = Array.prototype.slice
      .call(LOCATIONS)
      .sort((a, b) =>
        a.distance > b.distance ? 1 : b.distance > a.distance ? -1 : 0
      );
    return (
      <div
        className="town80book"
        style={{ display: "flex", flexDirection: "column", padding: "0% 10%" }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{}}>
            <p
              style={{
                margin: 0,
                borderBottom: "1px black solid",
                marginBottom: "10px",
              }}
            >
              Part
            </p>
            <b>{`${this.props.part.columns.displayname}`}</b>
          </div>
          <div style={{ flex: 1, paddingLeft: "30px" }}>
            <p
              style={{
                margin: 0,
                borderBottom: "1px black solid",
                marginBottom: "10px",
              }}
            >
              Details
            </p>
            {this.props.part.columns.purchasedescription.length > 65 ? (
              <span>{`${this.props.part.columns.purchasedescription.slice(
                0,
                65
              )}...`}</span>
            ) : (
              <span>{`${this.props.part.columns.purchasedescription}`}</span>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Form style={{ flex: 1 }} onKeyPress={this.onKeyPress}>
            <Form.Group style={{ margin: "30px 0px" }}>
              <Form.Control
                placeholder="Add Address"
                value={this.state.addressSearch}
                onChange={(e) => {
                  e.preventDefault();
                  this.setState({ addressSearch: e.target.value, error: "" });
                }}
                className="proxima_light"
                style={{
                  border: "1px black solid",
                  height: "65px",
                  paddingRight: "12%",
                  fontSize: "24px",
                }}
              ></Form.Control>
            </Form.Group>
          </Form>
          <img
            alt="sei"
            src={search_icon}
            style={{
              position: "absolute",
              height: "30px",
              right: "15%",
              top: 125,
            }}
          />
        </div>
        {this.state.error !== "" && (
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              color: "red",
              margin: 0,
            }}
          >
            {this.state.error}
          </p>
        )}
        <Button
          variant="none"
          style={{
            color: "white",
            // border: "2px #6d1835 solid",
            borderRadius: "15px",
            width: "50%",
            alignSelf: "center",
            backgroundColor: "#6d1835",
          }}
          onClick={() => this.distanceMatrix()}
        >
          {this.state.calculating ? "Calculating..." : "Calculate Distance"}
        </Button>
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            color: "grey",
            margin: "0px",
          }}
        >
          Click address to email vendor
        </p>

        <div style={{ marginTop: "18px" }}>
          {locationsSortedArray.map((item, idx) => {
            let subject = "";
            let bodyLine = "";
            if (this.state.account.company) {
              subject = `${this.state.account.company.name} Work Order #: <<Add # here>>`;
              bodyLine = `Hello Heartline Fitness,%0D%0A%0D%0A
    We have identified the following part in your inventory via Heartline Inventory App. And would like to assign this to the following work order.%0D%0A%0D%0A
    Part #: ${this.props.part.columns.displayname} from ${item.displayname}%0D%0A%0D%0A
    A new Work Order has already been created and dispatched to you.%0D%0A%0D%0A
    Work Order #: << ${this.state.account.company.name} to place link to WO or reference WO# >>`;
            } else {
              subject = "";
              bodyLine = "";
            }
            return (
              <div
                key={idx}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#6d1835",
                  borderRadius: "4px",
                  margin: "12px 20px",
                  padding: "15px 30px",
                }}
              >
                <a
                  href={`mailto:warranty@heartlinefitness.com?subject=${subject}&body=${bodyLine}`}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textDecoration: "none",
                    color: "white",
                  }}
                >
                  <span>{item.displayname}</span>
                  <div style={{ display: "flex" }}>
                    <span style={{ flex: 1 }}>Address:</span>
                    <span>{`On-Hand: ${item.quantityonhand}`}</span>
                  </div>
                  <div style={{ display: "flex" }}>
                    <span style={{ flex: 1 }}>{item.printaddress}</span>
                    <span>{`Allocated: ${item.quantityallocated}`}</span>
                  </div>
                  <p
                    style={{ margin: 0 }}
                  >{`Distance: ${item.distance} miles`}</p>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  render() {
    return (
      // <div>
      <div>
        <Modal
          show={this.props.show}
          onHide={this.hideModal}
          animation={false}
          centered
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title className="town80book">Part Inventory</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.props.show && this.renderDetails()}</Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default PartsDetail;
