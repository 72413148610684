import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Container, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { createOAuthHeader534 } from "../actions/types";
import search_icon from "../assets/buttons/search-icon.png";
import PartsDetail from "./PartsDetail";
import "../css/style.css";

class Parts extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      parts: [],
      filteredParts: [],
      searchText: "",
      // addressSearch:"",
      chosenPart: {},
      showPartDetails: false,
    };
  }
  async componentWillMount() {
    try {
      const accountId = localStorage.getItem("accountId");
      let AUTHORIZATION_HEADER = createOAuthHeader534(
        "GET",
        "534",
        "1",
        accountId
      );
      let partsDownload = await axios.get(
        "https://secret-retreat-76675.herokuapp.com/api/data/parts",
        {
          params: {
            accountId: accountId,
            auth_header: AUTHORIZATION_HEADER,
          },
        }
      );
      let partsArray = partsDownload.data.finalSearchResults;
      this.setState({ parts: partsArray });
    } catch (err) {
      console.log(err);
    }
  }
  renderParts() {
    let searchText = this.state.searchText;
    let partsArray = [];
    if (this.state.parts.length > 0) {
      this.state.parts.map((item) => {
        if (this.state.searchText === "") {
          partsArray.push(item);
        } else {
          if (
            (item.columns.displayname !== undefined &&
              item.columns.displayname
                .toLowerCase()
                .indexOf(searchText.toLowerCase()) !== -1) ||
            (item.columns.purchasedescription !== undefined &&
              item.columns.purchasedescription
                .toLowerCase()
                .indexOf(searchText.toLowerCase()) !== -1)
          ) {
            partsArray.push(item);
          }
        }
        return null;
      });
    }
    if (partsArray.length === 0) {
      return (
        <p
          style={{
            marginTop: "100px",
            display: "flex",
            justifyContent: "center",
            fontSize: "36px",
            color: "#efefef",
          }}
        >
          NO PARTS
        </p>
      );
    }
    return partsArray.map((item, idx) => {
      return (
        <div
          key={idx}
          style={{
            width: "260px",
            height: "200px",
            minHeight: "100px",
            borderRadius: "5px",
            backgroundColor: "#6d1835",
            margin: "8px",
            padding: "12px 15px",
          }}
          onClick={() =>
            this.setState({ showPartDetails: true, chosenPart: item })
          }
        >
          <p style={{ color: "white" }}>
            <b>{item.columns.displayname}</b>
          </p>
          <p style={{ color: "white" }}>{item.columns.purchasedescription}</p>
        </div>
      );
    });
  }
  handleClose = () => {
    this.setState({ showPartDetails: false, chosenPart: {} });
  };

  onKeyPress(event) {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  }
  render() {
    if (!this.state.parts) {
      return (
        <Container className="town80book" style={{ marginTop: "100px" }}>
          <p
            style={{
              marginTop: "100px",
              display: "flex",
              justifyContent: "center",
              fontSize: "36px",
              color: "black", // "#efefef"
            }}
          >
            No Parts Available
          </p>
        </Container>
      );
    }
    return (
      <Container className="town80book" style={{ marginTop: "100px" }}>
        {this.state.parts.length === 0 ? (
          <p
            style={{
              marginTop: "100px",
              display: "flex",
              justifyContent: "center",
              fontSize: "36px",
              color: "#efefef",
            }}
          >
            RETREIVING PARTS...
          </p>
        ) : (
          <Container
            className="justify-content-md-center"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginBottom: "35px",
              }}
            >
              <Col lg={8} md={8} sm={8}>
                {/* <div style={{ display: "flex", alignItems: "center" }}> */}
                <Form style={{ flex: 1 }} onKeyPress={this.onKeyPress}>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      placeholder="SEARCH PARTS"
                      value={this.state.searchText}
                      onChange={(e) => {
                        this.setState({ searchText: e.target.value });
                      }}
                      // className="proxima_light"
                      style={{
                        border: "1px black solid",
                        height: "65px",
                        fontSize: "24px",
                      }}
                    ></Form.Control>
                  </Form.Group>
                </Form>
                <img
                  alt="sei"
                  src={search_icon}
                  style={{
                    position: "absolute",
                    height: "30px",
                    right: 30,
                    top: 17,
                  }}
                ></img>
              </Col>
            </Row>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                marginBottom: "30px",
                justifyContent: "center",
              }}
            >
              {this.renderParts()}
            </div>

            <PartsDetail
              show={this.state.showPartDetails}
              close={this.handleClose}
              part={this.state.chosenPart}
            />
          </Container>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Parts);
