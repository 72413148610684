import React, { PureComponent } from "react";
import { Route, Switch, Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import firebase from "firebase/app";
import "firebase/auth";
import login_logo from "../assets/logo/LogoFinal.png";

import account_icon from "../assets/buttons/profileicon.png";
import "../css/style.css";
import "../css/submitted.css";

import { Login, SignUp, Account, Parts } from "./index";

import { Navbar, Nav, Image } from "react-bootstrap";

import {
  onASNameChange,
  onASClearFields,
} from "../reducers/AccountSetupReducer";

class Root extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      account: false,
    };
  }

  UNSAFE_componentWillMount() {
    let config = {
      apiKey: "AIzaSyBlGsA-ED89OaSDjj4iBR-ougv9AdUqRWY",
      authDomain: "heartline-inventory.firebaseapp.com",
      databaseURL: "https://heartline-inventory.firebaseio.com",
      projectId: "heartline-inventory",
      storageBucket: "heartline-inventory.appspot.com",
      messagingSenderId: "1086979181705",
    };
    if (!firebase.apps.length) {
      firebase.initializeApp(config);
    }
    const contactId = localStorage.getItem("contactId");
    const accountId = localStorage.getItem("accountId");
    if (contactId !== "undefined") {
      this.props.onASNameChange(null, accountId, contactId);
    }
  }

  render() {
    const expand = window.innerWidth > 1300;
    const spacer = expand ? "50px" : "25px";
    return (
      <div
        className="town80book"
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {/* If user is not logged in */}
        {!this.props.isLoggedIn ? (
          <div>
            <div
              style={{
                marginTop: "10vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Image src={login_logo} fluid />
            </div>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/sign-up">
                {!this.props.signedUp ? (
                  (localStorage.removeItem("signedUp"), (<SignUp />))
                ) : (
                  <div
                    style={{
                      marginTop: "50px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <p className="center">
                      PLEASE CHECK YOUR INBOX AND VERIFY YOUR EMAIL.
                    </p>
                    <Link to="/login" className="button">
                      LOGIN
                    </Link>
                  </div>
                )}
              </Route>
              <Route path="/" component={Login} />
            </Switch>
          </div>
        ) : (
          <div>
            <Navbar
              expand="lg"
              className="bs-navbar-collapse"
              style={{
                top: "54px",
                justifyContent: "space-between",
                margin: "0px 76px",
                alignItems: "center",
              }}
            >
              <Navbar.Brand
                as={Link}
                to="/parts"
                onClick={() => this.setState({ account: false })}
              >
                <Image
                  style={{
                    height: "81px",
                  }}
                  src={login_logo}
                ></Image>
              </Navbar.Brand>
              <div style={{ paddingTop: "8px" }}>
                {/* <Navbar.Collapse id="basic-navbar-nav"> */}
                <Nav className="mr-auto" activeKey="/parts">
                  <span style={{ width: spacer }} />
                  <button
                    style={{
                      border: "none",
                      backgroundColor: "white",
                    }}
                    className="account"
                    onClick={() => {
                      let account = !this.state.account;
                      this.setState({ account });
                    }}
                  >
                    <img
                      src={account_icon}
                      style={{ transform: "scale(0.9)" }}
                      alt="account"
                    ></img>
                  </button>
                </Nav>
              </div>
            </Navbar>

            <div
              className="account_toggle"
              style={{
                display: !this.state.account && "none",
              }}
              onClick={() => {
                let account = true;
                this.setState({ account });
              }}
            >
              <Account onASClearFields={this.props.onASClearFields} />
            </div>
            <div
              onClick={() => {
                if (this.state.account) {
                  let account = !this.state.account;
                  this.setState({ account });
                }
              }}
              style={{ flex: 1 }}
            >
              {!this.props.isLoggedIn ? (
                <Switch>
                  <Route exact path="/" component={Login} />
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/sign-up">
                    {!this.props.signedUp ? (
                      (localStorage.removeItem("signedUp"), (<SignUp />))
                    ) : (
                      <div>
                        Please check your inbox and verify your email. Then{" "}
                        <Link to="/login">Login</Link>
                      </div>
                    )}
                  </Route>
                  <Route
                    exact
                    path="/"
                    component={() => (
                      <div>
                        logged out not found
                        <Link to="/login"> To login screen </Link>
                      </div>
                    )}
                  />
                </Switch>
              ) : (
                <Switch>
                  <Route exact path="/" component={Parts} />
                  <Route exact path="/parts" component={Parts} />

                  <Route exact path="/login">
                    {this.props.isLoggedIn ? (
                      <Redirect to="/parts" />
                    ) : (
                      <Login />
                    )}
                  </Route>
                  <Route path="/" component={Parts}>
                    <Redirect to="/parts" />
                  </Route>
                </Switch>
              )}
            </div>
          </div>
        )}

        <div className="footer">
          <div className="nav_spacer"></div>
          <div
            className="town80book"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <p style={{ margin: 0 }}>(844) 338-9900</p>
            <p style={{ margin: 0 }}>service@heartlinefitness.com</p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: !!state.account.contactId,
  signedUp: !!state.account.displayName,
  email: state.auth.email,
});

export default withRouter(
  connect(mapStateToProps, { onASNameChange, onASClearFields })(Root)
);
