import oauthSignature from "oauth-signature";
import CryptoJS from "crypto-js";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ATTEMPTED = "LOGIN_ATTEMPTED";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LI_EMAIL_CHANGED = "LI_EMAIL_CHANGED";
export const LI_PASSWORD_CHANGED = "LI_PASSWORD_CHANGED";
export const LI_TOKEN_CHANGED = "LI_TOKEN_CHANGED";
export const LI_CLEARALL = "LI_CLEARALL";
export const LI_ERROR = "LI_ERROR";

export const AS_ATTEMPTED = "AS_ATTEMPTED";
export const AS_SUCCESS = "AS_SUCCESS";
export const AS_FAIL = "AS_FAIL";
export const AS_EMAIL_CHANGED = "AS_EMAIL_CHANGED";
export const AS_PASSWORD1_CHANGED = "AS_PASSWORD1_CHANGED";
export const AS_PASSWORD2_CHANGED = "AS_PASSWORD2_CHANGED";
export const AS_NAME_CHANGED = "AS_NAME_CHANGED";
export const AS_CLEARALL = "AS_CLEARALL";
export const AS_ERROR = "AS_ERROR";

export const LOCATIONS = [
  {
    name: "Warranty Depot - MD",
    displayname: "WD - Rockville",
    address: "4 Research Place Rockville, MD 20850",
    printaddress: `4 Research Place\nRockville, MD 20850`,
    distance: 0,
    quantityavailable: 0,
    quantityonhand: 0,
    quantityallocated: 0,
  },
  {
    name: "HN (Hoang Nguyen)",
    displayname: "WDT – Alex Nguyen",
    address: "4 Research Place Rockville MD 20850",
    printaddress: `4 Research Place\nRockville, MD 20850`,
    distance: 0,
    quantityavailable: 0,
    quantityonhand: 0,
    quantityallocated: 0,
  },
  {
    name: "EL (Eric Lafont)",
    displayname: "WDT – Eric Lafont",
    address: "5819 N. Andrews Way Suite A Fort Lauderdale, FL 33309",
    printaddress: `5819 N. Andrews Way Suite A\nFort Lauderdale, FL 33309`,
    distance: 0,
    quantityavailable: 0,
    quantityonhand: 0,
    quantityallocated: 0,
  },
];

export const createOAuthHeader521 = (
  http_method,
  script_id,
  script_deployment_id,
  email
) => {
  var NETSUITE_ACCOUNT_ID = "630587";
  var BASE_URL =
    "https://630587.restlets.api.netsuite.com/app/site/hosting/restlet.nl";
  var HTTP_METHOD = http_method;
  var SCRIPT_ID = script_id;
  var SCRIPT_DEPLOYMENT_ID = script_deployment_id;
  var TOKEN_ID =
    "3f1f571bc26ef2b581116d7bcd0aaddaefab6c6e356edca566e13e9cb7cac8e2";
  var TOKEN_SECRET =
    "70e7adec7eaf39dc8a1e9c67d7ef32a92a43638140b4cb182e6eddf888113d84";
  var CONSUMER_KEY =
    "8934682191b279fd9a37df90df9787f60e848a6143d322939a6c75ffec702f61";
  var CONSUMER_SECRET =
    "16e576794e3142435ab66b277ba341d412c680283783f48abbcc42f2cfec2162";
  var text = "";
  var length = 11;
  var possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  var OAUTH_NONCE = text;
  var TIME_STAMP = Math.round(+new Date() / 1000);

  let params = {
    deploy: SCRIPT_DEPLOYMENT_ID,
    email: email,
    app: "Inventory",
    oauth_consumer_key: CONSUMER_KEY,
    oauth_nonce: OAUTH_NONCE,
    oauth_signature_method: "HMAC-SHA1",
    oauth_timestamp: TIME_STAMP,
    oauth_token: TOKEN_ID,
    oauth_version: "1.0",
    script: SCRIPT_ID,
  };

  let OAUTH_SIGNATURE = oauthSignature.generate(
    HTTP_METHOD,
    BASE_URL,
    params,
    CONSUMER_SECRET,
    TOKEN_SECRET
  );

  // var data = '';
  // data = data + 'deploy=' + SCRIPT_DEPLOYMENT_ID + '&';
  // data = data + 'email=' + email + '&';
  // data = data + 'oauth_consumer_key=' + CONSUMER_KEY + '&';
  // data = data + 'oauth_nonce=' + OAUTH_NONCE + '&';
  // data = data + 'oauth_signature_method=' + 'HMAC-SHA1' + '&';
  // data = data + 'oauth_timestamp=' + TIME_STAMP + '&';
  // data = data + 'oauth_token=' + TOKEN_ID + '&';
  // data = data + 'oauth_version=' + OAUTH_VERSION + '&';
  // data = data + 'script=' + SCRIPT_ID;
  //
  // var encodedData = encodeURIComponent(data);
  // var completeData = HTTP_METHOD + '&' + encodeURIComponent(BASE_URL) + '&' + encodedData;
  // //completeData = completeData.replace('%40', '@');
  // console.log('completeData', completeData);
  // var hmacsha1Data = CryptoJS.HmacSHA1(completeData, CONSUMER_SECRET + '&' + TOKEN_SECRET);
  // //var base64EncodedData = Base64.stringify(hmacsha1Data);
  // var base64EncodedData = CryptoJS.enc.Base64.stringify(hmacsha1Data);
  // var OAUTH_SIGNATURE = encodeURIComponent(base64EncodedData);
  // console.log('OAUTH_SIGNATURE', OAUTH_SIGNATURE);

  var OAuth = 'realm="' + NETSUITE_ACCOUNT_ID + '",';
  OAuth = OAuth + 'oauth_consumer_key="' + CONSUMER_KEY + '",';
  OAuth = OAuth + 'oauth_token="' + TOKEN_ID + '",';
  OAuth = OAuth + 'oauth_signature_method="HMAC-SHA1",';
  OAuth = OAuth + 'oauth_timestamp="' + TIME_STAMP + '",';
  OAuth = OAuth + 'oauth_nonce="' + OAUTH_NONCE + '",';
  OAuth = OAuth + 'oauth_version="1.0",';
  OAuth = OAuth + 'oauth_signature="' + OAUTH_SIGNATURE + '"';

  return `OAuth ${OAuth}`;
};

export const createOAuthHeader534 = (
  http_method,
  script_id,
  script_deployment_id,
  vendorid
) => {
  var NETSUITE_ACCOUNT_ID = "630587";
  var BASE_URL =
    "https://630587.restlets.api.netsuite.com/app/site/hosting/restlet.nl";
  var HTTP_METHOD = http_method;
  var OAUTH_VERSION = "1.0";
  var SCRIPT_ID = script_id;
  var SCRIPT_DEPLOYMENT_ID = script_deployment_id;
  var TOKEN_ID =
    "3f1f571bc26ef2b581116d7bcd0aaddaefab6c6e356edca566e13e9cb7cac8e2";
  var TOKEN_SECRET =
    "70e7adec7eaf39dc8a1e9c67d7ef32a92a43638140b4cb182e6eddf888113d84";
  var CONSUMER_KEY =
    "8934682191b279fd9a37df90df9787f60e848a6143d322939a6c75ffec702f61";
  var CONSUMER_SECRET =
    "16e576794e3142435ab66b277ba341d412c680283783f48abbcc42f2cfec2162";
  var text = "";
  var length = 11;
  var possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  var OAUTH_NONCE = text;
  var TIME_STAMP = Math.round(+new Date() / 1000);

  // let params = {
  //   oauth_consumer_key : CONSUMER_KEY,
  //   oauth_token : TOKEN_ID,
  //   oauth_nonce : OAUTH_NONCE,
  //   oauth_timestamp : TIME_STAMP,
  //   oauth_signature_method : 'HMAC-SHA1',
  //   oauth_version : '1.0',
  // }

  //OAUTH_SIGNATURE = oauthSignature.generate(HTTP_METHOD, BASE_URL, params, CONSUMER_SECRET, TOKEN_SECRET);

  var data = "";
  data = data + "deploy=" + SCRIPT_DEPLOYMENT_ID + "&";
  data = data + "oauth_consumer_key=" + CONSUMER_KEY + "&";
  data = data + "oauth_nonce=" + OAUTH_NONCE + "&";
  data = data + "oauth_signature_method=HMAC-SHA1&";
  data = data + "oauth_timestamp=" + TIME_STAMP + "&";
  data = data + "oauth_token=" + TOKEN_ID + "&";
  data = data + "oauth_version=" + OAUTH_VERSION + "&";
  data = data + "script=" + SCRIPT_ID + "&";
  data = data + "vendorid=" + vendorid;

  var encodedData = encodeURIComponent(data);
  var completeData =
    HTTP_METHOD + "&" + encodeURIComponent(BASE_URL) + "&" + encodedData;

  var hmacsha1Data = CryptoJS.HmacSHA1(
    completeData,
    CONSUMER_SECRET + "&" + TOKEN_SECRET
  );
  //var base64EncodedData = Base64.stringify(hmacsha1Data);
  var base64EncodedData = CryptoJS.enc.Base64.stringify(hmacsha1Data);
  var OAUTH_SIGNATURE = encodeURIComponent(base64EncodedData);

  var OAuth = 'realm="' + NETSUITE_ACCOUNT_ID + '",';
  OAuth = OAuth + 'oauth_consumer_key="' + CONSUMER_KEY + '",';
  OAuth = OAuth + 'oauth_token="' + TOKEN_ID + '",';
  OAuth = OAuth + 'oauth_signature_method="HMAC-SHA1",';
  OAuth = OAuth + 'oauth_timestamp="' + TIME_STAMP + '",';
  OAuth = OAuth + 'oauth_nonce="' + OAUTH_NONCE + '",';
  OAuth = OAuth + 'oauth_version="1.0",';
  OAuth = OAuth + 'oauth_signature="' + OAUTH_SIGNATURE + '"';

  return `OAuth ${OAuth}`;
};

export const createOAuthHeader535 = (
  http_method,
  script_id,
  script_deployment_id,
  itemid
) => {
  var NETSUITE_ACCOUNT_ID = "630587";
  var BASE_URL =
    "https://630587.restlets.api.netsuite.com/app/site/hosting/restlet.nl";
  var HTTP_METHOD = http_method;
  var OAUTH_VERSION = "1.0";
  var SCRIPT_ID = script_id;
  var SCRIPT_DEPLOYMENT_ID = script_deployment_id;
  var TOKEN_ID =
    "3f1f571bc26ef2b581116d7bcd0aaddaefab6c6e356edca566e13e9cb7cac8e2";
  var TOKEN_SECRET =
    "70e7adec7eaf39dc8a1e9c67d7ef32a92a43638140b4cb182e6eddf888113d84";
  var CONSUMER_KEY =
    "8934682191b279fd9a37df90df9787f60e848a6143d322939a6c75ffec702f61";
  var CONSUMER_SECRET =
    "16e576794e3142435ab66b277ba341d412c680283783f48abbcc42f2cfec2162";
  var text = "";
  var length = 11;
  var possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  var OAUTH_NONCE = text;
  var TIME_STAMP = Math.round(+new Date() / 1000);

  // let params = {
  //   oauth_consumer_key: CONSUMER_KEY,
  //   oauth_token: TOKEN_ID,
  //   oauth_nonce: OAUTH_NONCE,
  //   oauth_timestamp: TIME_STAMP,
  //   oauth_signature_method: "HMAC-SHA1",
  //   oauth_version: "1.0",
  // };

  //OAUTH_SIGNATURE = oauthSignature.generate(HTTP_METHOD, BASE_URL, params, CONSUMER_SECRET, TOKEN_SECRET);

  var data = "";
  data = data + "deploy=" + SCRIPT_DEPLOYMENT_ID + "&";
  data = data + "id=" + itemid + "&";
  data = data + "oauth_consumer_key=" + CONSUMER_KEY + "&";
  data = data + "oauth_nonce=" + OAUTH_NONCE + "&";
  data = data + "oauth_signature_method=HMAC-SHA1&";
  data = data + "oauth_timestamp=" + TIME_STAMP + "&";
  data = data + "oauth_token=" + TOKEN_ID + "&";
  data = data + "oauth_version=" + OAUTH_VERSION + "&";
  data = data + "recordtype=inventoryitem&";
  data = data + "script=" + SCRIPT_ID;

  var encodedData = encodeURIComponent(data);
  var completeData =
    HTTP_METHOD + "&" + encodeURIComponent(BASE_URL) + "&" + encodedData;

  var hmacsha1Data = CryptoJS.HmacSHA1(
    completeData,
    CONSUMER_SECRET + "&" + TOKEN_SECRET
  );
  //var base64EncodedData = Base64.stringify(hmacsha1Data);
  var base64EncodedData = CryptoJS.enc.Base64.stringify(hmacsha1Data);
  var OAUTH_SIGNATURE = encodeURIComponent(base64EncodedData);

  var OAuth = 'realm="' + NETSUITE_ACCOUNT_ID + '",';
  OAuth = OAuth + 'oauth_consumer_key="' + CONSUMER_KEY + '",';
  OAuth = OAuth + 'oauth_token="' + TOKEN_ID + '",';
  OAuth = OAuth + 'oauth_signature_method="HMAC-SHA1",';
  OAuth = OAuth + 'oauth_timestamp="' + TIME_STAMP + '",';
  OAuth = OAuth + 'oauth_nonce="' + OAUTH_NONCE + '",';
  OAuth = OAuth + 'oauth_version="1.0",';
  OAuth = OAuth + 'oauth_signature="' + OAUTH_SIGNATURE + '"';

  return `OAuth ${OAuth}`;
};
