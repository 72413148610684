import React from "react";

import "./App.css";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import Root from "./components/Root";
import store from "./store";
import history from "./history";

import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <Root />
      </Router>
    </Provider>
  );
}

export default App;
